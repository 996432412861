/* eslint-disable max-len */
import { get } from 'lodash';

import { getCurrentDeck, setCurrentDeck } from '#Utilities/storage';
import api from '#Utilities/api';
import client from '#Utilities/graphql';
import { displayError } from '#Actions/error/error';
import * as types from '#Constants/ActionTypes';
import { paths } from '#Config/paths';
import {
    changeActivePage,
    getIndexOfNextPage,
    savePageBeforeRemove
} from '#Actions/build/page';
import { selectPage } from '#Actions/plan/topics/topics';
import { getUserSelectedTopicsWithStoryboardDefaults } from '#Selectors/plan';
import { deckIsLoading } from '#Selectors/deck';
import { hideSaveButton } from '#Actions/plan/plan';
import {
    updateFileProperties,
    populateElasticSearchBody
} from '#Actions/manage/manage';

const searchEngineIsElasticSearch = searchEngine => searchEngine === 'ELASTICSEARCH';
const ESSyncVariables = {
    timeToWait: 700,
    requestDelayFactor: 1.5,
    elasticSearchReqBody: { resourceOwnership: ['private'] }
};

const fetchDeck = (id, keepCurrentFile = false) => (dispatch, getState) => {
    if (deckIsLoading(getState())) {
        return Promise.resolve();
    }
    dispatch({ type: types.FETCH_DECK });
    return client
        .fetchDeck(id)
        .then(deck => {
            if (deck) {
                setCurrentDeck(deck.id);
            }
            dispatch({
                type: types.FETCH_DECK_SUCCESS,
                deck,
                keepCurrentFile
            });
        })
        .catch(err => {
            console.error(err);
            return dispatch({ type: types.FETCH_DECK_FAIL });
        });
};

const setDeck = ({
    deckId,
    deckVersion,
    deckTitle,
    pages = [],
    activePageNumber = 1,
    isPublic,
    isSharedDeck
}) => dispatch => {
    setCurrentDeck(deckId);

    dispatch({
        type: types.SET_DECK,
        deckId,
        deckVersion,
        deckTitle,
        pages,
        activePageNumber,
        isSharedDeck,
        isPublic
    });
};

const fetchCurrentDeck = () => dispatch => {
    dispatch({ type: types.FETCH_DECK });

    client
        .fetchCurrentDeck()
        .then(deck => {
            setCurrentDeck(deck.id);

            dispatch({
                type: types.FETCH_DECK_SUCCESS,
                deck,
                keepCurrentFile: true
            });
        })
        .catch(() => dispatch({ type: types.FETCH_DECK_FAIL }));
};

const getLocalDeck = () => dispatch => {
    const deckIdFromLocalStorage = getCurrentDeck();
    if (deckIdFromLocalStorage) {
        dispatch(fetchDeck(deckIdFromLocalStorage));
    } else {
        dispatch(fetchCurrentDeck());
    }
};

const createDeck = () => dispatch => {
    dispatch({ type: types.CREATE_DECK });

    return client
        .createDeck()
        .then(deck => {
            dispatch({ type: types.CREATE_DECK_SUCCESS, deck });
            setCurrentDeck(deck.id);
        })
        .catch(e => {
            dispatch({ type: types.CREATE_DECK_FAIL });
            switch (e.toString()) {
                case 'Error: GraphQL error: No valid subscription':
                    dispatch({
                        type: types.SHOW_SUBSCRIPTION_WARNING,
                        message: 'To create a deck, you must have a subscription.',
                        title: 'Subscription required'
                    });
                    break;
                case 'Error: GraphQL error: Free user can only create one deck':
                    dispatch({
                        type: types.SHOW_SUBSCRIPTION_WARNING,
                        message: 'Accounts with a free plan can only create one deck.',
                        title: 'Premium account required'
                    });
                    break;
                default:
                    break;
            }
        });
};

const elasticSearchDeckIsOlder = (elasticSearchDeck, localDeck) => {
    const elasticSearchDeckTimestamp = parseInt(elasticSearchDeck.version, 36);
    const localDeckTimestamp = parseInt(localDeck.version, 36);
    return elasticSearchDeckTimestamp < localDeckTimestamp;
};

const delayCall = (callable, delay) => new Promise((resolve, reject) => setTimeout(() => callable().then(resolve).catch(reject), delay));

const waitForElasticSearchSync = (
    localDeck,
    timeToWait,
    elasticSearchReqBody
) => (dispatch, getState) => {
    const currentSearchBody = getState().manage.search.body;
    const elasticSearchBody = populateElasticSearchBody(
        elasticSearchReqBody,
        currentSearchBody
    );
    return api
        .post({
            path: paths.searchRepoUrl,
            body: {
                elasticSearchParams: elasticSearchBody,
                url: ''
            }
        })
        .then(files => {
            const elasticSearchDeck = files.find(file => file.id === localDeck.id);
            if (
                !elasticSearchDeck ||
        !elasticSearchDeckIsOlder(elasticSearchDeck, localDeck)
            ) {
                return localDeck;
            }
            const newTimeToWait = timeToWait * ESSyncVariables.requestDelayFactor;
            return delayCall(
                () => dispatch(
                    waitForElasticSearchSync(
                        localDeck,
                        newTimeToWait,
                        elasticSearchReqBody
                    )
                ),
                timeToWait
            );
        });
};

const updateDeckAfterServerUpdate = (
    deck,
    updateBody = {},
    waitForSyncWithElasticSearch = false
) => (dispatch, getState) => {
    const { app: { config: { searchEngine } } } = getState();
    const searchUpdatePromise = (
        searchEngineIsElasticSearch(searchEngine) &&
            waitForSyncWithElasticSearch ?
            dispatch(
                waitForElasticSearchSync(
                    deck,
                    ESSyncVariables.timeToWait,
                    ESSyncVariables.elasticSearchReqBody
                )
            ) :
            Promise.resolve(deck)
    );

    return searchUpdatePromise
        .then(() => {
            dispatch({ type: types.CHANGE_DECK_PROPERTIES, deck });
            // In manage section, the files list comes from elasticSearch,
            // so when some file properties change, the list needs to be synced
            dispatch(updateFileProperties(deck.id, updateBody));
            dispatch({ type: types.SET_MANAGE_SHOULD_FETCH_TRUE });
        })
        .catch(err => {
            dispatch(displayError(err));
        });
};

const updateDeckPropertiesFromPlan = (
    body,
    waitForSyncWithElasticSearch = false
) => (dispatch, getState) => {
    const {
        deck: { id: deckId }
    } = getState();
    return client
        .updateDeckFromPlan(deckId, body)
        .then(deck => dispatch(
            updateDeckAfterServerUpdate(deck, body, waitForSyncWithElasticSearch)
        ));
};

const updateDeckTitle = (body, waitForSyncWithElasticSearch = false) => (
    dispatch,
    getState
) => {
    const {
        deck: { id: deckId }
    } = getState();
    return client
        .updateDeckTitle(deckId, body.title)
        .then(deck => dispatch(
            updateDeckAfterServerUpdate(deck, body, waitForSyncWithElasticSearch)
        ));
};

const updatePageProperties = (pageId, body) => dispatch => api.put({ path: `pages/${pageId}`, body }).then(() => {
    dispatch({ type: 'UPDATE_PAGE_PROPERTIES' });
    // In manage section, the files list comes from elasticSearch,
    // so when some file properties change, the list needs to be synced
    dispatch(updateFileProperties(pageId, body));
});

const createPagesOperations = (currentPages, newTopics, selectedTopics) => {
    const pagesOperations = [...currentPages, ...newTopics];
    return pagesOperations.map(page => {
        if (page.id !== undefined && (selectedTopics.some(topic => topic.id === page.topic.id) || page.topic.id === 'BLANK_TOPIC')) {
            return { operationName: 'NO_OP', arguments: { pageId: page.id } };
        } if (page.id !== undefined && !selectedTopics.some(topic => topic.id === page.topic.id) && page.topic.id !== 'BLANK_TOPIC') {
            return {
                operationName: 'REMOVE_PAGE',
                arguments: { pageId: page.id }
            };
        }
        return {
            operationName: 'ADD_PAGE',
            arguments: { topic: page.topic, index: page.index }
        };
    });
};

const shouldSavePagesOperations = pagesOperations => pagesOperations.some(pageOp => pageOp.operationName !== 'NO_OP');

const orderedTopicsByPlanTopics = (topics, planTopics) => {
    const orderedTopics = [];
    planTopics.forEach(planTopic => {
        const foundTopic = topics.find(topic => topic.id === planTopic.topic.id);
        if (foundTopic) {
            orderedTopics.push(foundTopic);
        }
    });
    return orderedTopics;
};

const updateDeckFromPlanIfNeeded = () => (dispatch, getState) => new Promise(resolve => {
    const state = getState();
    const { deck, plan } = state;
    const selectedTopics = getUserSelectedTopicsWithStoryboardDefaults(state);

    if (!deck || !deck.pages) {
        return resolve();
    }
    dispatch(hideSaveButton());

    const objective = get(plan, 'initialObjective.id', null);
    const storyboard = get(plan, 'initialStoryboard.id', null);

    const body = { objective, storyboard, pagesOperations: null };

    const newTopics = [];
    const filteredTopics = selectedTopics.filter(topic => !deck.pages.some(page => page.topic.id === topic.id));
    const orderedFilteredTopics = orderedTopicsByPlanTopics(filteredTopics, plan.topics);
    orderedFilteredTopics.forEach(orderedTopic => {
        newTopics.push({
            topic: orderedTopic.id,
            index: orderedFilteredTopics.indexOf(orderedTopic) + deck.pages.length
        });
    });

    body.pagesOperations = createPagesOperations(deck.pages, newTopics, selectedTopics);

    if (shouldSavePagesOperations(body.pagesOperations)) {
        dispatch({ type: types.UPDATE_DECK });
        return dispatch(updateDeckPropertiesFromPlan(body, true));
    }
    return null;
});

const changeDeckPagesOrder = (pageIds, followActivePage = true) => (
    dispatch,
    getState
) => {
    const {
        deck: { id: deckId },
        build: { activePage: currentPage }
    } = getState();
    dispatch({ type: types.REORDER_STARTED, pageIds });
    return client
        .reorderPages(deckId, pageIds)
        .then(modifiedDeck => {
            dispatch({
                type: types.LOAD_DECK,
                deck: modifiedDeck
            });

            if (followActivePage) {
                dispatch(
                    changeActivePage(currentPage.id, { reason: types.REORDER_STARTED })
                );
            }
        })
        .finally(() => dispatch({ type: types.REORDER_ENDED }));
};

const changeDeckPagesOrderForPlan = (pageIds, followActivePage = true) => (
    dispatch,
    getState
) => {
    const {
        deck: { id: deckId },
        build: { activePage: currentPage }
    } = getState();
    dispatch({ type: types.REORDER_STARTED, pageIds });
    return client
        .reorderPages(deckId, pageIds)
        .then(modifiedDeck => {
            dispatch({
                type: types.LOAD_DECK,
                deck: modifiedDeck
            });

            if (followActivePage) {
                dispatch(changeActivePage(currentPage.id));
            }
        })
        .finally(() => dispatch({ type: types.REORDER_ENDED }));
};

const removeDeckPage = (pageId, payload) => (dispatch, getState) => {
    const state = getState();
    const { deck } = state;
    const indexBeforeRemoval = deck.pages.findIndex(
        page => page._id === pageId
    );
    const activePageIndexBeforeRemoval = deck.pages.findIndex(
        page => page._id === state.build.activePage.id
    );
    const removedPageIndex = deck.pages.findIndex(page => page._id === pageId);

    dispatch({ type: types.REMOVE_PAGE_STARTED });
    return savePageBeforeRemove()(dispatch, getState)
        .then(newPage => {
            const newPageId = newPage ? newPage.newId : pageId;

            return client.removePage(deck.id, newPageId).then(modifiedDeck => {
                dispatch({
                    type: types.REMOVE_PAGE_IN_CURRENT_DECK,
                    deck: modifiedDeck,
                    pageId: newPageId,
                    pageIndex: removedPageIndex,
                    ...payload
                });
                if (modifiedDeck.pages.length === 0) {
                    dispatch(changeActivePage(null, { reason: 'REMOVE_PAGE' }));
                    dispatch(selectPage(null));
                    return;
                }
                if (pageId === state.build.activePage.id) {
                    const id =
            pageId !== deck.pages[deck.pages.length - 1].id ?
                getIndexOfNextPage(state, 'down') :
                getIndexOfNextPage(state, 'up');
                    dispatch(changeActivePage(id, { reason: 'REMOVE_PAGE' }));
                } else if (activePageIndexBeforeRemoval > indexBeforeRemoval) {
                    dispatch(
                        changeActivePage(
                            modifiedDeck.pages[activePageIndexBeforeRemoval - 1].id,
                            { reason: 'REMOVE_PAGE' }
                        )
                    );
                } else {
                    dispatch(
                        changeActivePage(
                            modifiedDeck.pages[activePageIndexBeforeRemoval].id,
                            { reason: 'REMOVE_PAGE' }
                        )
                    );
                }

                if (
                    state.plan.selectedPage &&
          state.plan.selectedPage.id === newPageId
                ) {
                    let newSelected = null;
                    if (modifiedDeck.pages.length) {
                        const oldIndex = deck.pages.findIndex(p => p.id === newPageId);
                        newSelected =
              modifiedDeck.pages[
                  oldIndex === modifiedDeck.pages.length ? oldIndex - 1 : oldIndex
              ];
                    }
                    dispatch(selectPage(newSelected));
                }
            });
        })
        .finally(() => dispatch({ type: types.REMOVE_PAGE_ENDED }));
};

const deleteDeck = deckId => dispatch => {
    dispatch({ type: types.DELETE_DECK });
    setCurrentDeck(null);

    client
        .deleteDeck(deckId)
        .then(() => dispatch({ type: types.DELETE_DECK_SUCCESS }));
};

const applyCurrentLayoutToAllPagesWithSameLayoutName = () => (
    dispatch,
    getState
) => {
    const {
        build: { canvasState },
        deck
    } = getState();
    return client
        .updateAllPageWithLayout(deck.id, {
            name: canvasState.get('layoutName'),
            shapes: canvasState.get('layoutShapes'),
            background: canvasState.get('layoutBackground'),
            height: canvasState.get('size').height,
            width: canvasState.get('size').width
        })
        .then(({
            deck: newDeck,
            layouts,
            unaffectedShapes,
            unaffectedShapesProperties
        }) => {
            dispatch({
                type: types.UPDATE_DECK_LAYOUTS,
                deck: newDeck,
                list: layouts
            });
            dispatch({
                type: types.APPLY_LAYOUT_ON_PAGE,
                unaffectedShapes,
                unaffectedShapesProperties
            });
        });
};

const createNewVersionOfLayout = () => (dispatch, getState) => {
    const {
        build: { canvasState },
        deck
    } = getState();
    return client
        .createNewVersionOfLayout(deck.id, {
            name: canvasState.get('layoutName'),
            shapes: canvasState.get('layoutShapes'),
            background: canvasState.get('layoutBackground'),
            height: canvasState.get('size').height,
            width: canvasState.get('size').width
        })
        .then(({
            newLayoutName,
            layouts
        }) => {
            dispatch({
                type: types.CHANGE_LAYOUT_NAME,
                name: newLayoutName
            });
            dispatch({
                type: types.LOAD_LAYOUTS,
                list: layouts
            });
            dispatch({
                type: types.APPLY_LAYOUT_ON_PAGE
            });
        });
};
const addTemporaryPagesAndStoryboard = (dispatch, getState) => {
    const { plan } = getState();
    const selectedTopics = getUserSelectedTopicsWithStoryboardDefaults(
        getState()
    );

    dispatch({
        type: types.ADD_PAGES_FROM_TOPIC,
        pages: selectedTopics.map(topic => ({
            topic,
            waitForUpdate: true
        }))
    });

    dispatch({
        type: types.CHANGE_DECK_STORYBOARD,
        storyboard: plan.selectedStoryboard
    });
};

const duplicateDeck = (deckToDuplicate = undefined) => (dispatch, getState) => {
    dispatch({ type: types.DUPLICATE_DECK });
    const state = getState();
    const deckIdToDuplicate = deckToDuplicate ?
        deckToDuplicate._id :
        `${state.deck.id}-${state.deck.version}`;
    return client
        .duplicateDeck(deckIdToDuplicate)
        .then(deck => {
            dispatch({ type: types.DECK_DUPLICATED });
            setCurrentDeck(deck.id);
            dispatch({
                type: types.SET_DECK,
                deckId: deck.id,
                deckTitle: deck.title,
                pages: deck.pages,
                activePageNumber: deck.pageNumber,
                isSharedDeck: deck.isSharedDeck
            });
            return deck;
        })
        .catch(err => {
            dispatch({ type: types.DUPLICATE_DECK_FAILED });
            throw err;
        });
};

export {
    getLocalDeck,
    fetchDeck,
    removeDeckPage,
    createDeck,
    updateDeckFromPlanIfNeeded,
    updatePageProperties,
    updateDeckPropertiesFromPlan,
    updateDeckTitle,
    changeDeckPagesOrder,
    changeDeckPagesOrderForPlan,
    deleteDeck,
    setDeck,
    applyCurrentLayoutToAllPagesWithSameLayoutName,
    addTemporaryPagesAndStoryboard,
    createNewVersionOfLayout,
    updateDeckAfterServerUpdate,
    duplicateDeck
};
