import { getAuthorizationHeader, clearTokens } from '#Utilities/cognito';
import { authNavConfig } from '#Config/navigation.config';

import { history } from '../configureStore';

const urlBase = `${process.env.DECKREPO_URL}/api`;

const call = ({
    method,
    url,
    body,
    headers,
    signal,
    parse
}) => {
    const headersObject = new Headers(headers);
    return getAuthorizationHeader()
        .then(authHeader => {
            if (authHeader) {
                headersObject
                    .append('Authorization', authHeader);
            }

            return window
                .fetch(new window.Request(
                    url,
                    {
                        method,
                        body: body instanceof FormData ?
                            body :
                            JSON.stringify(body),
                        headers: headersObject
                    }
                ), {
                    credentials: 'include',
                    signal
                });
        })
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    clearTokens();
                    history.replace({
                        pathname: authNavConfig.login.path
                    });

                    throw new Error(response.statusText);
                } else if (response.status === 403) {
                    history.replace({
                        pathname: '/subscribe'
                    });

                    throw new Error(response.statusText);
                }

                return response.text()
                    .then(text => {
                        throw new Error(text || response.statusText);
                    });
            }

            if (response.redirected && response.url.indexOf('login') !== -1) {
                history.replace({
                    pathname: authNavConfig.login.path
                });
            }

            switch (parse) {
                case 'arrayBuffer':
                case 'formData':
                case 'json':
                case 'text':
                case 'blob':
                    if (response.status === 204) {
                        return null;
                    }
                    return response[parse]();
                default:
                    return response;
            }
        });
};

const createUrl = ({ path = '', query = {} }) => {
    const url = new window.URL(`${urlBase}/${path}`);
    Object
        .entries(query)
        .forEach(([key, value]) => url.searchParams.set(key, value));
    return url;
};

export default {
    get: ({
        path = '',
        query = {},
        headers = {
            'Content-Type': 'application/json'
        },
        signal = undefined,
        parse = 'json'
    }) => call({
        method: 'GET',
        url: createUrl({ path, query }),
        signal,
        headers,
        parse
    }),

    post: ({
        path = '',
        body = {},
        query = {},
        headers = {
            'Content-Type': 'application/json'
        },
        parse = 'json'
    }) => call({
        method: 'POST',
        url: createUrl({ path, query }),
        body,
        headers,
        parse
    }),

    put: ({
        path = '',
        body = {},
        query = {},
        headers = {
            'Content-Type': 'application/json'
        },
        parse = 'json'
    }) => call({
        method: 'PUT',
        url: createUrl({ path, query }),
        body,
        headers,
        parse
    }),

    delete: ({
        path = '',
        body = {},
        query = {},
        headers = {
            'Content-Type': 'application/json'
        },
        parse = 'json'
    }) => call({
        method: 'DELETE',
        url: createUrl({ path, query }),
        body,
        headers,
        parse
    }),

    urlBase
};
