import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { getAuthorizationHeader } from '#Utilities/cognito';
import { authNavConfig } from '#Config/navigation.config';
import { history } from '../../configureStore';

import cache from './cache';

const baseGraphQlLink = createHttpLink({
    uri: `${process.env.DECKREPO_URL}/api/graphql`,
    credentials: 'include'
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message }) => {
            if (message === 'Authentication Error') {
                history.replace({
                    pathname: authNavConfig.login.path
                });
            }
            return null;
        });
    }
    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

const getCognitoLink = () => getAuthorizationHeader()
    .then(authHeader => setContext((_, { headers }) => ({
        headers: {
            ...headers,
            authorization: authHeader
        }
    })));

export const makeClient = (withAuth = true) => {
    if (withAuth) {
        return getCognitoLink(withAuth)
            .then(cognitoLink => new ApolloClient({
                cache,
                link: cognitoLink.concat(onErrorLink).concat(baseGraphQlLink),
                defaultOptions: {
                    query: {
                        fetchPolicy: 'cache-first'
                    }
                }
            }));
    }
    return Promise.resolve(new ApolloClient({
        cache,
        link: onErrorLink.concat(baseGraphQlLink),
        defaultOptions: {
            query: {
                fetchPolicy: 'cache-first'
            }
        }
    }));
};
