import { paths } from '#Config/paths';

let pageImageCache = {};
let thumbnailCache = {};

const emptyCache = () => {
    pageImageCache = {};
    thumbnailCache = {};
};

const baseUrl = `${process.env.DECKREPO_URL}/api`;

const generatePageThumbnailUrl = (s3Thumbnail, imageSrc, noCache = true) => {
    if (!imageSrc) {
        return null;
    }

    let s3ImageSrc = `${baseUrl}`;
    s3ImageSrc += `/${s3Thumbnail}`;
    s3ImageSrc += `/${imageSrc}`;
    s3ImageSrc += `_${paths.thumbnails}.svg`;

    if (noCache) {
        return s3ImageSrc;
    }

    if (!pageImageCache[imageSrc]) {
        pageImageCache[imageSrc] = s3ImageSrc;
    }

    return pageImageCache[imageSrc];
};

const generatePageImageUrl = (s3Images, imageSrc, noCache = true) => {
    if (!imageSrc) {
        return null;
    }

    let s3ThumbnailSrc = `${baseUrl}`;
    s3ThumbnailSrc += `/${s3Images}`;
    s3ThumbnailSrc += `/${imageSrc}.svg`;

    if (noCache) {
        return s3ThumbnailSrc;
    }

    if (!thumbnailCache[imageSrc]) {
        thumbnailCache[imageSrc] = s3ThumbnailSrc;
    }

    return thumbnailCache[imageSrc];
};

const generateThemeThumbnailUrl = ({ themesThumbnailsURLBase, themeId, image }) => `${baseUrl}/${themesThumbnailsURLBase}/${themeId}/${image}`;

export {
    emptyCache,
    generatePageThumbnailUrl,
    generatePageImageUrl,
    generateThemeThumbnailUrl
};
